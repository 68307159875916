<template>
  <div class="pagination-custom">
    <button
      v-if="showPrevious"
      class="item prev"
      @click="changePage(current - 1)"
    >
      &laquo;
    </button>
    <button
      v-for="(page, index) in pages"
      :key="page"
      class="item"
      :class="{ current: page === current }"
      @click="changePage(index)"
    >
      {{ page }}
    </button>
    <button v-if="showNext" class="item next" @click="changePage(current + 1)">
      &raquo;
    </button>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    offset: {
      type: [String, Number],
      default: 0,
    },
    total: {
      type: [String, Number],
      required: true,
    },
    limit: {
      type: [String, Number],
      default: 10,
    },
  },
  computed: {
   
    showPrevious() {
      return this.current > 1;
    },
    showNext() {
      return this.total > this.limit * this.current;
    },
    current() {
      return this.offset ? this.offset + 1 : 1;
    },
    pages() {
      const qty = Math.ceil(this.total / this.limit);
      if (qty <= 1) return [1];
      return Array.from(Array(qty).keys(), (i) => i + 1);
    },
  },
  methods: {
    changePage(offset) {
      this.$emit("change-page", offset);
    },
  },
};
</script>

<style lang="scss" scoped>
$light-grey: #cccccc;
$dark-grey: #333333;
.pagination-custom {
  margin-left: 50%;
  .item {
    padding: 0.5rem 0.75rem;
    right: 15px;
    border: none;
    cursor: pointer;
    background-color: white;
    &:hover {
      background-color: $light-grey;
      z-index: 3;
    }
    &.current {
      cursor: default;
      color: white;
      background-color: var(--proj-primary);
      z-index: 2;
    }
  }
}
.pagination {
  .item {
    padding: 0.5rem 0.75rem;
    position: absolute;
    right: 15px;
    border: none;
    cursor: pointer;
    background-color: white;
    &:hover {
      background-color: $light-grey;
      z-index: 3;
    }
    &.current {
      cursor: default;
      color: white;
      background-color: var(--proj-primary);
      z-index: 2;
    }
    + .item {
      margin-left: -1px;
      margin-right: 0;
    }
  }
}
</style>