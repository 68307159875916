<template>
  <div>
    <Modal
      v-model="showModalCreate"
      title="Criar nova Habilidade"
      :enableClose="false"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="disciplina">Componente Curricular</label>
            <select
              id="disciplina"
              v-model="disciplinaSelecionada"
              class="form-control"
            >
              <option
                v-for="disciplina in disciplinas"
                :key="disciplina.id"
                :value="disciplina.id"
              >
                {{ disciplina.text }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="ano">Ano</label>
            <select id="ano" v-model="anoEnsino" class="form-control">
              <option v-for="ano in anos" :key="ano.id" :value="ano.id">
                {{ ano.titulo }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="titulo">Título</label>
        <input id="titulo" v-model="input" type="text" class="form-control" />
      </div>
      <hr class="full-hr" />
      <div class="row">
        <div class="col-sm-12">
          <div class="float-right">
            <button
              type="button"
              class="btn btn-classic"
              :disabled="!input"
              @click.prevent="salvar"
              id="salvarAssunto"
            >
              Salvar
            </button>
            <button
              type="button"
              class="btn btn-secondary m-2"
              @click="showModalCreate = false"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="
              page-header
              d-flex
              justify-content-between
              align-items-center
              mb-3
              pt-4
            "
          >
            <h1 class="color-sec">Habilidades</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Habilidades
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-3 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        id="novoAssunto"
                        class="btn btn-classic"
                        @click.prevent="openModalCreate"
                      >
                        <i class="fa fa-plus" /> NOVO
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="
                  block__classic
                  table
                  table__classic
                  table-striped
                  table-borderless
                  table-hover
                  text-center
                  mb-4
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Título</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filteredRows" :key="item.id">
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.text)"
                    />
                    <td class="text-right" style="min-width: 170px;">
                      <button
                        id="editarAssunto"
                        v-if="podeEditar(item)"
                        class="btn btn-sm btn-classic"
                        @click.prevent="editar(item)"
                      >
                        <i class="far fa-edit" />
                      </button>
                      <button
                        v-if="podeEditar(item)"
                        class="btn btn-sm btn-danger m-2"
                        @click.prevent="deletar(item.id)"
                      >
                        <i class="far fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination
                v-if="filteredRows.length"
                :offset="offset"
                :total="total"
                :limit="limit"
                @change-page="changePage"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import Swal from "sweetalert2";
import Pagination from "@/components/pagination/Pagination";
import { constantes } from "@/constants";
import { checkUserHaveRole } from "@/tools";

export default {
  components: {
    Pagination,
    MainTopBar,
    MainLeftSideBar,
    Modal: VueModal,
  },
  data() {
    return {
      offset: 0,
      total: 0,
      edit: false,
      limit: 50,
      showLeftMenu: true,
      showModalCreate: false,
      disciplinaSelecionada: null,
      anoEnsino: null,
      disciplinas: [],
      anos: [],
      user: [],
      id: null,
      index: -1,
      input: null,
      items: [],
      filter: "",
      instituicao: localStorage.getItem(constantes.STORAGE_UNIAVAN_ID_INSTITUICAO) || null,
    };
  },
  computed: {
    filteredRows() {
      return this.items.filter((row) => {
        const searchTerm = this.filter.toLowerCase();
        const text = row.text.toLowerCase();
        return text.includes(searchTerm);
      });
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();
    this.loadDisciplinas();
    this.loadAnos();
    this.loadUser();
  },
  methods: {
    changePage(value) {
      this.offset = value;
      this.getPassengers();
    },
    podeEditar(item) {
      if(checkUserHaveRole([constantes.ROLE_SUPER_ADMIN])){
        return true;
      }
      const ehCriadorAssunto = this.user && this.user.user && this.user.user.email && item.criadoPor != undefined && item.criadoPor.email == this.user.user.email;
      if(ehCriadorAssunto){
        return true;
      }
      if(this.user.user.tipoUsuario === 'TIPO_USUARIO_PROFESSOR'){
        return false;
      }
      if(this.instituicao && item.instituicao && this.instituicao == item.instituicao.id){
        return true;
      }
      return false;                        
    },
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.id = null;
      this.input = null;
      this.index = -1;
      this.disciplinaSelecionada = null;
    },
    salvar() {
      this.saveServer();
      // this.saveServer(() => {
      //   if (this.index >= 0) {
      //     this.items[this.index].text = this.input;
      //   } else {
      //     var item = { id: null, text: this.input };
      //     this.items.unshift(item);
      //   }
      // });

      this.showModalCreate = false;
    },

    deletar(id) {
      console.log(id);
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção da habilidade?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$root.$emit("Spinner::show");

          this.$api.delete("assuntos/" + id).then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Habilidade removida com sucesso!",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(function () {
              location.reload();
            }, 1500);
          });
        }
      });
    },

    editar(item) {
      this.id = item.id;
      this.input = item.text;
      this.disciplinaSelecionada = item.disciplina.id;
      this.edit = true;
      this.anoEnsino = item.anoEnsino && item.anoEnsino.id ? item.anoEnsino.id : null;
      this.showModalCreate = true;
    },
    loadUser() {
      this.$api
        .get("users/info")
        .then((response) => {
          console.log("eu", response.data);
          this.user = response.data;
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    loadData() {
      this.$root.$emit("Spinner::show");

      this.$api.get("lista-assuntos").then((response) => {
        console.log(response);
        this.items = response.data;
        this.$root.$emit("Spinner::hide");
      });
    },
    saveServer(callback) {
      this.$root.$emit("Spinner::show");
        var assunto = {
          text: this.input,
          anoEnsino: this.anoEnsino,
          disciplina: this.disciplinaSelecionada,
        };
      if (
        localStorage.getItem(constantes.STORAGE_UNIAVAN_ID_INSTITUICAO) != 0
      ) {
        assunto.instituicao = parseInt(
            localStorage.getItem(constantes.STORAGE_UNIAVAN_ID_INSTITUICAO)
          );
        assunto.criadoPor = this.user.user.id;
      }

      console.log(assunto);

      if (this.edit) {
        this.$api.put("assuntos/" + this.id, assunto).then(() => {
          this.$root.$emit("Spinner::hide");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Habilidade alterada com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(function () {
            location.reload();
          }, 1000);
        });
      } else {
        this.$api.post("assuntos", assunto).then(() => {
          this.$root.$emit("Spinner::hide");
          this.loadData();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Habilidade cadastrada com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(function () {
            location.reload();
          }, 1000);

          // callback();
        });
      }
    },
    loadDisciplinas() {
      // this.$api.get("disciplinas").then((response) => {
      //   console.log(response);
      //   this.disciplinas = response.data;
      //   this.$root.$emit("Spinner::hide");
      // });
      this.$root.$emit("Spinner::show");

      this.$api.get("disciplinas").then((response) => {
        console.log(response);
        // this.disciplinas = response.data;
        // this.disciplinasDisponiveis = response.data;
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.disciplinas.push(res);
            // this.disciplinasDisponiveis.push(res);
          }
        });
        this.$root.$emit("Spinner::hide");
      });
    },
    loadAnos() {
      this.$api.get("ano_ensinos").then((response) => {
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.anos.push(res);
          }
        });
        console.log(this.anos);
        this.$root.$emit("Spinner::hide");
      });
    },
  },
};
</script>
